<template>
  <ErrorCatcher>
    <ReloadPrompt />
    <Toaster position="top-center" />

    <component :is="$route.meta.layoutComponent">
      <RouterView />
    </component>
  </ErrorCatcher>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch, nextTick } from 'vue'
import { Toaster } from 'vue-sonner'
import { useWakeLock, useOnline } from '@vueuse/core'
import ReloadPrompt from '@/components/ReloadPrompt.vue'
import ErrorCatcher from '@/components/ErrorCatcher.vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'
import useCookieLocale from '@/composables/useCookieLocale'
import { useUserDataStore } from '@/stores/user'
import * as Sentry from '@sentry/vue'
import { logError } from './utils/error-logger'

const { isSupported } = useWakeLock()
const { t, locale } = useI18n({ useScope: 'global' })
const { currentLang } = useCookieLocale()
const userStore = useUserDataStore()
const online = useOnline()

const startTimeOffline = ref<number>(0)

const toasterID = ref<number | string | null>(null)

watch(
  online,
  (newIsOnline, oldIsOnline) => {
    // Show "You are offline" tip
    nextTick(() => {
      console.log('watch is Online', newIsOnline)
      if (!newIsOnline) {
        toasterID.value = toaster.error(t('generalTips.offline'), {
          dismissible: false,
          closeButton: false,
          duration: Infinity
        })
      } else if (toasterID.value !== null) {
        toaster.dismiss(toasterID.value)
      }
    })

    // Skip it on first page load
    if (typeof oldIsOnline === 'undefined' || typeof newIsOnline === 'undefined') {
      return
    }

    if (!newIsOnline && oldIsOnline) {
      // Go offline
      startTimeOffline.value = new Date().getTime()
    }

    // Go online
    if (!oldIsOnline && newIsOnline) {
      // Elapsed seconds
      const _elapsedTime = startTimeOffline.value
        ? Math.round((new Date().getTime() - startTimeOffline.value) / 1000)
        : 0

      logError('User was offline', 'App.vue => watch', {
        offlineDurationInSeconds: _elapsedTime
      })

      startTimeOffline.value = 0
    }
  },
  { immediate: true }
)

onMounted(async () => {
  if (userStore.userData?.user?.username) {
    Sentry.setUser({
      email: userStore.userData?.user?.username,
      username: userStore.userData?.user?.username
    })
  }

  if (userStore.userData?.user?.lang || currentLang.value) {
    locale.value = (userStore.userData?.user?.lang || currentLang.value) as string
    userStore.changeLocale(locale.value)
  } else {
    currentLang.value = navigator.language
  }

  if (!isSupported.value) {
    toaster.warn(t('wakeLock.notSupported'))
    return
  }
})
</script>
