import { logError } from '@/utils/error-logger'
import { toaster } from '@/utils/toaster'
import { Capacitor } from '@capacitor/core'
import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service'
import { onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'

export const useForegroundService = () => {
  const { t } = useI18n()

  const requestPermissions = async () => {
    if (Capacitor.getPlatform() !== 'android') {
      return false
    }

    const { display } = await ForegroundService.checkPermissions()

    console.log('display: ', display)
    if (display !== 'granted') {
      const { display: permission } = await ForegroundService.requestPermissions()

      console.log('permission: ', permission)
      if (permission !== 'granted') {
        throw new Error('requestPermissions permission is not grunted')
      }

      // // It's asking Overlay permission to check if it works without it. it that case remove it in the future
      // const { granted } = await ForegroundService.checkManageOverlayPermission()

      // if (!granted) {
      //   const { granted } = await ForegroundService.requestManageOverlayPermission()

      //   if (!granted) {
      //     throw new Error('requestManageOverlayPermission permission is not grunted')
      //   }
      // }
    }
  }

  const startForegroundService = async () => {
    try {
      if (Capacitor.getPlatform() !== 'android') {
        return true
      }

      await requestPermissions()

      await ForegroundService.startForegroundService({
        title: t(`capacitorAndroid.notification.title`),
        body: t(`capacitorAndroid.notification.body`),
        id: 123,
        smallIcon: 'logo_green'
      })

      return true
    } catch (error: any) {
      toaster.error(t(`capacitorAndroid.toaster.permissionIsNotGrunted`))

      logError(
        'Error in ForegroundService: Permission is not granted. Please provide all needed permissions to start recording',
        'useAudioRecording.ts:_startForegroundService()',
        error
      )

      return false
    }
  }

  const stopForegroundService = async () => {
    try {
      if (Capacitor.getPlatform() !== 'android') {
        return true
      }
      await ForegroundService.stopForegroundService()
      return true
    } catch (error: any) {
      toaster.error(t(`capacitorAndroid.toaster.stopForegroundServiceError`))

      logError(
        'Error in ForegroundService: error on ForegroundService.stopForegroundService',
        'useAudioRecording.ts:_stopForegroundService()',
        error
      )
      return false
    }
  }

  onUnmounted(() => {
    stopForegroundService()
  })

  return {
    requestPermissions,
    startForegroundService,
    stopForegroundService
  }
}
