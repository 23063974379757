import type { ILoginForm, IResetPasswordForm, ISignUpForm } from '@/types'
import { http } from '@/utils/http'
import axios from 'axios'

class UserDataService {
  async getToken(form: ILoginForm, logData: any) {
    const bURL = String(import.meta.env?.VITE_BASE_URL).endsWith('/')
      ? import.meta.env?.VITE_BASE_URL
      : `${import.meta.env?.VITE_BASE_URL}/`

    return await axios.post(
      `${bURL}auth/login`,
      { ...form, logData },
      {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }
    )
  }

  async refreshToken() {
    return await http.post(`/auth/token-refresh`)
  }

  async login({ access_token = '', token_type = '' }) {
    let headers = {}

    if (access_token && token_type) {
      const authorization: string =
        (token_type?.slice(0, 1) || '').toUpperCase() + token_type.slice(1) + ' ' + access_token
      headers = { Authorization: authorization }
    }

    return await http.get(`/users/me`, { headers })
  }

  async createUser(form: ISignUpForm) {
    return await http.post(`/users`, form)
  }

  async getResetToken(username: string) {
    return await http.post(`/auth/forgot-password`, { username })
  }

  async resetPassword(form: IResetPasswordForm) {
    return await http.patch(`/auth/reset-password`, form)
  }

  async changeLocale(lang: string) {
    return await http.post(`/users/change-language`, { lang })
  }
}

export default new UserDataService()
