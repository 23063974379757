<template>
  <TransitionRoot as="template" :show="visibilityControl">
    <Dialog class="relative z-[10000]" @close="handleClose">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div :id="props.id" class="fixed inset-0 z-[10000] w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center text-center">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative h-[85vh] max-h-[616px] min-w-[880px] max-w-[880px] transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:w-full"
            >
              <div class="flex h-full items-stretch overflow-hidden">
                <div class="flex h-full grow flex-col gap-4 p-10 pr-6">
                  <div class="overflow-auto">
                    <BaseMarkdownRender :markdown="props.markdown" />
                  </div>

                  <div class="sticky bottom-0 mt-auto flex justify-end gap-6">
                    <button
                      id="tryItNowBtn"
                      type="button"
                      class="flex h-[40px] items-center justify-center rounded-[4px] bg-accent-500 px-9 text-white hover:opacity-80"
                      @click="handleOk"
                    >
                      {{ $t('dialogs.newFeatureDialog.actions.ok') }}
                    </button>
                  </div>
                </div>

                <div v-if="props.image" class="relative w-[410px] shrink-0 grow-0">
                  <img
                    :src="props.image"
                    alt="new-features preview"
                    class="absolute right-0 top-0 block h-full w-full object-cover"
                  />
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'
import BaseMarkdownRender from './base/BaseMarkdownRender.vue'

interface Props {
  id?: string
  visible?: boolean
  disableOverlay?: boolean
  markdown?: string
  image?: any
}

const props = withDefaults(defineProps<Props>(), {
  type: 'info',
  disableOverlay: false,
  visible: false,
  markdown: '',
  image: undefined
})
const emit = defineEmits<{
  'update:visible': [boolean]
  cancel: []
  close: []
  ok: []
}>()

const visibilityControl = useVModel(props, 'visible', emit, { passive: true })

function handleOk() {
  toggleVisibility()
  emit('ok')
}

function toggleVisibility() {
  visibilityControl.value = !visibilityControl.value
}

function handleClose() {
  if (!props.disableOverlay) {
    toggleVisibility()
    emit('close')
  }
}
</script>
