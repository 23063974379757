import { ref } from 'vue'
import { toaster } from '@/utils/toaster'
import { useI18n } from 'vue-i18n'
import dayjs from '@/utils/dayjs'

export function useTimer(updateTimerCallback: Function = () => {}) {
  const { t } = useI18n()

  const durationMms = ref<number>(0)
  const duration = ref<string>('00:00')
  const _startTime = ref<number>()
  const _elapsedTime = ref(0)
  const _timerInterval = ref<any>(null)

  function startTimer() {
    _startTime.value = dayjs().valueOf() - _elapsedTime.value
    _timerInterval.value = setInterval(_updateTimer, 1000)
    toaster.success(t('consultationIndexView.recordingProcess.toaster.capturingStarted'))
  }

  function pauseTimer() {
    if (_timerInterval.value) {
      clearInterval(_timerInterval.value)
    }
    _elapsedTime.value = dayjs().valueOf() - (_startTime.value || dayjs().valueOf())
    toaster.info(t('consultationIndexView.recordingProcess.toaster.capturingPaused'))
  }

  function resumeTimer() {
    startTimer()
    toaster.info(t('consultationIndexView.recordingProcess.toaster.capturingResume'))
  }

  function stopTimer() {
    clearInterval(_timerInterval.value)
    _elapsedTime.value = 0
    _updateTimer(true)

    resetTimer()
  }

  function _updateTimer(isSilent: boolean = false) {
    const currentTime = dayjs().valueOf()
    const _elapsedTime = _startTime.value ? currentTime - _startTime.value : 0

    const hours = Math.floor(_elapsedTime / (1000 * 60 * 60))
    const minutes = Math.floor((_elapsedTime % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((_elapsedTime % (1000 * 60)) / 1000)

    durationMms.value = _elapsedTime

    if (hours > 0) {
      duration.value = `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    } else {
      duration.value = `${minutes}:${seconds.toString().padStart(2, '0')}`
    }

    if (!isSilent) {
      updateTimerCallback()
    }
  }

  function resetTimer() {
    duration.value = '00:00'
    durationMms.value = 0
    _startTime.value = undefined
    _elapsedTime.value = 0
    _timerInterval.value = null
  }

  return {
    startTimer,
    pauseTimer,
    resumeTimer,
    stopTimer,
    resetTimer,
    durationMms,
    duration
  }
}
