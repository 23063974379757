<template>
  <div class="prose" v-html="html" />
</template>

<script lang="ts" setup>
import { marked } from 'marked'
import { computed } from 'vue'

interface IProps {
  markdown: string
}

const props = withDefaults(defineProps<IProps>(), {
  markdown: ''
})
const html = computed(() => (props.markdown ? marked(props.markdown) : ''))
</script>
