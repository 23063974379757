export default {
  capacitorAndroid: {
    notification: {
      title: 'Die PlaynVoice-App nimmt auf',
      body: 'Klicken Sie, um die App zu öffnen und den Aufnahmeprozess zu verwalten.'
    },
    toaster: {
      permissionIsNotGrunted:
        'Berechtigung nicht erteilt. Bitte erteilen Sie alle erforderlichen Berechtigungen, um die Aufnahme zu starten.',
      stopForegroundServiceError:
        'FEHLER bei ForegroundService.stopForegroundService, bitte kontaktiere das Support-Team.'
    }
  },
  generalTips: {
    notFound: 'Nicht gefunden',
    loading: 'Lädt …',
    offline: 'Du bist offline, bitte überprüfe deine Internetverbindung.'
  },
  welcome: {
    greeting: 'Willkommen bei',
    message:
      'KI-Dokumentationssoftware für Gesundheitsdienstleister in der mentalen Gesundheit. Spare Zeit und verbessere deine Aufmerksamkeit.',
    reset: {
      title1: 'Zurücksetzen',
      title2: 'Passwort'
    },
    signUp: {
      title1: 'Erstelle dein',
      title2: 'Konto jetzt'
    }
  },
  formControls: {
    email: {
      label: 'E-Mail',
      placeholder: 'Gib deine E-Mail ein'
    },
    password: {
      label: 'Passwort',
      placeholder: 'Gib dein Passwort ein'
    },
    confirmPassword: {
      label: 'Passwort bestätigen',
      placeholder: 'Gib dein Passwort erneut ein'
    },
    fullName: {
      label: 'Vollständiger Name',
      placeholder: 'Gib deinen vollständigen Namen ein'
    },
    iamProfessionalHealthProvider: {
      label: 'Ich bin professioneller Gesundheitsdienstleister'
    },
    acceptTOS: {
      term: 'Ich stimme den',
      TC: 'Allgemeinen Geschäftsbedingungen',
      divider: 'und der',
      DPA: 'Datenverarbeitungsvereinbarung'
    }
  },
  signInActions: {
    login: 'Anmelden',
    logout: 'Abmelden',
    lostPassword: 'Passwort vergessen?',
    continue: 'Weiter',
    goToLogin: 'Zum Login gehen',
    tips: {
      emailSent: 'Wir habe dir gerade eine E-Mail mit einem Link zum Zurücksetzen geschickt.',
      checkEmail:
        'Wenn deine E-Mail registriert ist, erhältst du in Kürze einen Link zum Zurücksetzen des Passworts.',
      accountCreatedSuccessfully: 'Konto erfolgreich erstellt!',
      resetPasswordFailed: 'Passwort zurücksetzen fehlgeschlagen.',
      resetPasswordSuccessfully: 'Passwort wurde erfolgreich zurückgesetzt.'
    },
    backToHome: 'Zurück zur Startseite',
    signUp: 'Registrieren',
    resetPassword: 'Passwort zurücksetzen'
  },
  formValidationErrorMessages: {
    usernamePassword: 'Bitte gib Benutzernamen und Passwort ein.',
    username: 'Bitte gib deinen Benutzernamen ein.',
    fullName: 'Bitte gib deinen vollständigen Namen ein.',
    email: 'Bitte gib deine E-Mail-Adresse ein.',
    invalidEmail: 'E-Mail ist ungültig',
    password: 'Bitte gib dein Passwort ein.',
    confirmPassword: 'Bitte bestätige dein Passwort.',
    passwordAndConfirmPasswordNotEqual: 'Passwörter stimmen nicht überein.',
    iamProfessionalHealthProvider: 'Du musst ein Gesundheitsdienstleister sein.',
    acceptTOS: 'Bitte stimme den AGB und der AVV zu.'
  },
  wakeLock: {
    notSupported: 'Wake Lock wird vom aktuellen Browser nicht unterstützt.'
  },
  dialogs: {
    newFeatureDialog: {
      actions: {
        ok: 'Jetzt ausprobieren!',
        cancel: 'Abbrechen'
      }
    },
    default: {
      actions: {
        ok: 'Ok',
        cancel: 'Abbrechen'
      }
    },
    deleteConsultation: {
      title: 'Löschen der Konsultation bestätigen',
      actions: {
        ok: 'Akzeptieren'
      }
    },
    micWasLostDialog: {
      title: 'Mikrofonzugriff verloren, Aufnahme beenden, um die Daten zu speichern.',
      actions: {
        ok: 'Konsultation beenden',
        cancel: 'Konsultation abbrechen'
      }
    },
    cancelConsultation: {
      title: 'Möchtst du die laufende Konsultation wirklich abbrechen?',
      actions: {
        ok: 'Ja',
        cancel: 'Nein'
      }
    },
    reloadPage: {
      title: 'Neue Inhalte verfügbar',
      content: 'Klicke auf die Aktualisieren-Knopf, um neu zu laden.',
      actions: {
        reload: 'Aktualisieren'
      }
    },
    transcriptionsConsultationFeedback: {
      thumbsUpTooltip: 'Nützlich für mich',
      thumbsDownTooltip: 'Nicht nützlich für mich',
      thumbsUpTitle: 'Bitte erkläre, warum diese Notiz für dich nützlich war.',
      thumbsDownTitle: 'Bitte erkläre, warum diese Notiz für dich nicht nützlich war.',
      contentPlaceholder: {
        line1: '- Lass uns wissen, was dir gefallen hat und was wir verbessern könnten.',
        line2:
          '\nWir lesen jedes Feedback und nutzen es, um das Produkt zu verbessern. Wer weiss, vielleicht haben wir ja schon eine Lösung für dich! ;) '
      },
      actions: {
        submit: 'Abschicken'
      },
      toaster: {
        thanksForFeedback: 'Danke für dein Feedback',
        canNotBeRetrieved: 'Feedback konnte nicht abgerufen werden.',
        feedbackNotSent: 'Feedback wurde nicht gesendet.'
      }
    },
    useChromeTips: {
      title: 'Bitte benutze den Chrome-Browser',
      content: 'Um Probleme zu vermeiden, empfehlen wir dir die Nutzung des Chrome-Browsers.',
      actions: {
        understand: 'Ich verstehe'
      }
    },
    deviceAndBrowserTips: {
      androidNotChrome: {
        title: 'Nützliche Tipps',
        content1: 'Wir empfehlen dir, den Chrome-Browser zu verwenden.',
        content2:
          'Bitte versetze dein Telefon nicht in den Ruhemodus, da dies die Audio-Transkription stoppt.',
        content3:
          'Platziere das Telefon zwischen dir und dem Patienten oder in der Nähe, um eine gute Audioqualität zu gewährleisten.',
        actions: { ok: 'Ok' }
      },
      androidFirefox: {
        title: 'Achtung: Der Firefox-Browser wird nicht unterstützt',
        content1:
          'Firefox kann in den Standby-Modus gehen und die Transkription deiner Konsultation stoppen. Bitte verwende Chrome.',
        actions: { ok: 'Ich verstehe' }
      },
      androidChrome: {
        title: 'Nützliche Tipps',
        content1:
          'Bitte versetze dein Telefon nicht in den Ruhemodus, da dies die Audio-Transkription stoppt.',
        content2:
          'Platziere das Telefon zwischen dir und dem Patienten oder in der Nähe, um eine gute Audioqualität zu gewährleisten.',
        actions: { ok: 'Ok' }
      },
      ios: {
        title: 'Nützliche Tipps',
        content1:
          'Platziere das Telefon zwischen dir und dem Patienten oder in der Nähe, um eine gute Audioqualität zu gewährleisten.',
        actions: { ok: 'Ok' }
      },
      mobileFirefox: {
        title: 'Firefox wird nicht unterstützt',
        content: 'Bitte benutze Chrome oder Safari Browser.',
        actions: { ok: 'Ok' }
      }
    },
    forceEndConsultationDueToDurationLimitation: {
      title: 'Wir haben deine Konsultation nach 2 Stunden beendet',
      content: {
        line1: 'Dies ist die aktuelle Höchstdauer.',
        line2: 'Deine Notizen werden erstellt.'
      },
      actions: {
        ok: 'Ok'
      }
    }
  },
  consultationIndexView: {
    waitingScreens: {
      toaster: {
        wsConnectionFailed: 'Verbindung mit WebSocket nach 15 Versuchen fehlgeschlagen'
      },
      consolidation: {
        title: 'Schliesse die App nicht!',
        description: 'Die Verarbeitung startet in wenigen Sekunden…'
      },
      aiProcessing: {
        title: 'KI-Magie passiert...',
        description:
          'Deine Notiz wird in wenigen Minuten fertig sein. Du kannst jetzt eine neue Konsultation starten.'
      },
      errorHappened: {
        title: 'Während der Verarbeitung ist ein Fehler aufgetreten',
        description: 'Das PlaynVoice-Team wird es beheben. Komm später zurück.',
        description2: 'Danke für deine Geduld!'
      }
    },
    details: {
      notes: 'Konsultationsnotizen',
      noteTypesTitle: {
        standard: 'Standardnotizen',
        intake: 'Anamnesenotizen',
        progress: 'Verlaufsnotizen',
        diagnostic: 'Diagnostische Notizen',
        diagnosticASS: 'ASS Interview',
        collateralInterview: 'ASS Fremdinterview',
        interviewADHS: 'ADHS Interview',
        custom: 'Custom'
      },
      save: 'Speichern',
      copy: 'Kopieren',
      toaster: {
        descriptionEmpty: 'Die Beschreibung der Konsultation darf nicht leer sein.',
        notesUpdated: 'Notizen wurden aktualisiert.',
        editNotSaved: 'Änderungen wurden nicht gespeichert.',
        noteLengthUpdateError: 'Fehler beim Aktualisieren der Notizlänge.',
        noteTypeUpdateError: 'Fehler beim Aktualisieren des Notiztyps.'
      },
      here: 'hier',
      lostTimeNotification:
        'Warnung: {time} der Konsultation ging verloren. Klicke auf {link}, um mögliche Gründe und Lösungen zu sehen.',
      badAudioQualityNotification:
        'Warnung: Schlechte Audioqualität. Dadurch kann die Qualität der generierten Notizen beeinträchtigt werden. Bitte überprüfe die Positionierung des Mikrofons.',
      maxLengthConsultationNotification:
        'Warnung: Diese Konsultation wurde nach 2 Stunden automatisch beendet. Daher ist diese Notiz möglicherweise nicht vollständig.'
    },
    recordingProcess: {
      androidTip: 'Sperre dein Telefon nicht',
      initCapture: 'Aufnahme wird initialisiert...',
      capturing: 'Aufnahme läuft...',
      clickTOCapture: 'Klicke, um die Konsultation aufzunehmen',
      processingAudio: {
        line1: 'Notizen sind in ein paar Minuten fertig.',
        line2: 'Du kannst eine neue Konsultation starten.'
      },
      toaster: {
        capturingComplete: 'Aufnahme abgeschlossen',
        capturingCanceled: 'Aufnahme abgebrochen',
        capturingStarted: 'Aufnahme gestartet',
        capturingPaused: 'Aufnahme pausiert',
        capturingResume: 'Aufnahme wird fortgesetzt',
        micAccessDoesNotProvided:
          'Ohne Mikrofonberechtigung kann keine Transkription durchgeführt werden.'
      },
      noteSettings: {
        title: 'Notizeinstellungen',
        template: 'Vorlage',
        noteLength: 'Notizlänge',
        lengthsTip: {
          short: 'Kurze Notizen',
          standard: 'Standard Notizen',
          long: 'Lange Notizen'
        }
      }
    },
    info: {
      inputDevice: 'Eingabegerät'
    },
    actions: {
      newConsultation: 'Neue Konsultation',
      newVisit: 'Neue Konsultation',
      notes: 'Notizen',
      startConsultation: 'Konsultation starten',
      endConsultation: 'Konsultation beenden',
      cancelConsultation: 'Abbrechen',
      pause: 'Pause',
      resume: 'Fortsetzen'
    }
  },
  consultationStoreActions: {
    consultationRemoved: 'Die Konsultation wurde entfernt',
    deletingError: 'Fehler beim Löschen der Notizen.'
  }
}
