import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

/** the path have to be in the format "./example/" */
export const useMarkdownContent = (path: string) => {
  const markdown = ref('')
  const { locale } = useI18n()

  async function loadMarkdown() {
    try {
      const response = await fetch(`${path}${locale.value}.md`)
      const text = await response.text()
      return text
    } catch {
      return ''
    }
  }

  onMounted(async () => {
    markdown.value = await loadMarkdown()
  })

  return {
    markdown
  }
}
