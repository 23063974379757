<template>
  <select :value="locale" @change="handleSetLocale">
    <option v-for="locale in availableLocales" :key="locale" :value="locale" class="uppercase">
      {{ locale }}
    </option>
  </select>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { useUserDataStore } from '@/stores/user'
import userDataService from '@/services/userDataService'

const userStore = useUserDataStore()

const { locale, availableLocales } = useI18n({ useScope: 'global' })

const handleSetLocale = (event: any) => {
  userStore.changeLocale(event.target.value)
  userDataService.changeLocale(event.target.value)
}
</script>
